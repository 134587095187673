@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
    }
  }

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 200ms ease-in-out;
    opacity: 0;
    z-index: 1000;
}

.Overlay.ReactModal__Overlay--after-open{
    opacity: 1;
}

.Overlay.ReactModal__Overlay--before-close{
    opacity: 0;
}

.Modal {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background: white;
    padding: 20px;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.Modal::-webkit-scrollbar {
    display: none;
}

.Modal.ReactModal__Content--after-open{
    opacity: 1;
}

.Modal.ReactModal__Content--before-close{
    opacity: 0;
}
